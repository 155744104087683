* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.Header-container {
  width: 100%;
}

.Header-container header {
  margin: auto;
  max-width: 85rem;

}

.Header-container header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

header div nav {
  display: inline-block;
}

header div nav ul {
  display: inline-block;
}

header div nav ul li {
  list-style: none;
  display: inline-block;
  vertical-align: baseline;
}

.account-header {
  font-size: 17px;
  color: #8c929c;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: 'BebasNeue', sans-serif;
  cursor: pointer;
}

header div nav ul li a{
  font-size: 17px;
  color: #8c929c;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: 'BebasNeue', sans-serif;
}

header div nav ul li a:hover {
  text-decoration: underline;
  text-underline-offset: 15px; /* Adjust this value to set the desired gap */
  color: navy;
}

.account-header:hover {
  text-decoration: underline;
  text-underline-offset: 15px; /* Adjust this value to set the desired gap */
  color: navy;
}

.schlogo img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  vertical-align: baseline;
  margin-left: -10px;
}

.Header-container header div .dropicon {
  display: none;
}

.Header-container header div .dropicon span {
  color: #333;
  cursor: pointer;
  font-size: 20px;
}

.side-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  transition: transform  2s ease-in;

}

.side-menu.open {
  display: block;
  transform: translateX(0);
  transition:  transform 2s ease-in;

}

.side-menu ul {
  justify-content: center;
  list-style: none;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.side-menu ul li {
  padding: 1rem 0;
}


.side-menu ul li a:hover {
  text-decoration: underline;
  color: navy;
}

.main-nav {
  display: none;
}

.collapsible-container{
  background-color: #000000d1;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.collapsible-container div .getstarted{
  color: white;
  margin-top: 50px;
}

.collapsible-containers{
  margin-bottom: 10rem;
  display: flex;
}

.collapsible-containers .dropdown-link{
  border: none;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 20px;
  width: 121px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
}


.Account-detail{
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.Account-detail p{
  color: #fff;
  font-size: 20px;
  font-family: 'fansong', sans-serif;
}


.Account-detail button{
  border: none;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 3rem;
  width: 121px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  display: block;
 
}

.googlecon{
      display: flex;
    justify-content: right;
    position: fixed;
    z-index: 1000;
    left: 30px;
}

@media (min-width: 900px) {

  .googlecon{
      display: flex;
    justify-content: right;
    position: fixed;
    z-index: 1000;
    left: 20px;
}

  
.side-menu ul li a, .account-header  {
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

  .side-menu {
    display: none;
  }

  .main-nav {
    display: inline-block;
  }

  .Header-container header div .dropicon {
    display: none;
  }

  .Header-container header div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 800px) {
  .main-nav {
    display: none;
  }

  .Header-container header div .dropicon {
    display: block;
  }

  .Header-container header div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.3rem;
    padding-right: 0.5rem;
  }


  .collapsible-containers .dropdown-link{
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 20px;
    width: 121px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
  }
  
  .collapsible-container div .getstarted{
    color: white;
    margin-top: 50px;
    font-size: 18px;
  }


  .Account-detail p{
    color: #fff;
    font-size: 16px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .googlecon{
      display: flex;
    justify-content: right;
    position: fixed;
    z-index: 1000;
    left: 10px;
}
  
  
}



@media (max-width: 410px) {

  .collapsible-container div .getstarted{
    color: white;
    margin-top: 50px;
    font-size: 17px;
  }
  

  .collapsible-containers .dropdown-link{
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 20px;
    width: 121px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
  }

  .googlecon{
      display: flex;
    justify-content: right;
    position: fixed;
    z-index: 1000;
    left: 5px;
}
}

@media (max-width: 350px) {

  .collapsible-containers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .collapsible-containers .dropdown-link{
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 20px;
    width: 121px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
  }

  .collapsible-container{
    background-color: #000000d1;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}