.book-search-container {
    font-family: Arial, sans-serif;
  }


  .bookbg{
    background: url('../Images/home.0c7180386ca9923ba35c.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;    
  }
  
  
  .search-bar {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

  }

  .searchinputcon{
    display: flex;
  }
  
  .search-input {
    padding: 10px;
    width: 600px;
    border: none;
    border-radius: 4px;
    height: 50px;
    outline: none;
  }
  
  .search-input.error {
    border-color: red;
  }
  
  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 61px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    background-color: #252563;
    color: white;
    cursor: pointer;
    height: 50px;
}

  
  .search-button:disabled {
    background-color: #008bc4;
    cursor: not-allowed;
  }

  
  
  .error-message {
    color: red;
    text-align: center;
    margin-top: 10px;
  }
  
  .loading {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
  }
  
  .books-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .book-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 22%;
    text-align: center;
  }
  
  .book-card h3 {
    font-size: 18px;
  }
  
  .book-card p {
    font-size: 16px;
    color: #555;
  }
  
  .book-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
  }
  
  .book-link {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }


  /* BookSearch.css */

/* Add other existing styles here */

/* Styling for the message */
.message {
  font-size: 14px; /* Adjust the font size as needed */
  font-weight: 400; /* Make the text bold */
  color: #333; /* Change the text color */
  padding: 10px 15px; /* Padding inside the box */
  margin: 20px 0; /* Space around the box */
  text-align: center; /* Center the text */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


 
  
  @media (max-width: 1200px) {
    .book-card {
      width: 30%;
    }
  }
  
  @media (max-width: 768px) {
    .book-card {
      width: 45%;
    }

    .search-input {
      padding: 10px;
      width: 400px;
      border: none;
      border-radius: 4px;
      height: 45px;
      outline: none;
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 61px;
      margin-left: 10px;
      border: none;
      border-radius: 4px;
      background-color: #252563;
      color: white;
      cursor: pointer;
      height: 45px;
  }

  .bookbg{
    background: url('../Images/home.0c7180386ca9923ba35c.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
    background-position: bottom;     
  }
  
  
  }
  
  @media (max-width: 576px) {
    .book-card {
      width: 90%;
    }

    .bookbg{
    background: url('../Images/home.0c7180386ca9923ba35c.png') no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 300px;
      overflow: hidden;
      position: relative;
      background-position: bottom;     
    }

    .search-bar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
  
    }
  }
  

  @media (max-width: 500px) {
    .search-input {
      padding: 10px;
      width: 300px;
      border: none;
      border-radius: 4px;
      height: 40px;
      outline: none;
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 43px;
      margin-left: 10px;
      border: none;
      border-radius: 4px;
      background-color: #252563;
      color: white;
      cursor: pointer;
      height: 40px;
  }
  
  }

  @media (max-width: 400px) {
    .search-input {
      padding: 10px;
      width: 250px;
      border: none;
      border-radius: 4px;
      height: 35px;
      outline: none;
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 40px;
      margin-left: 10px;
      border: none;
      border-radius: 4px;
      background-color: #252563;
      color: white;
      cursor: pointer;
      height: 35px;
  }
  
  }


  @media (max-width: 311px) {
    .search-input {
      padding: 10px;
      width: 190px;
      border: none;
      border-radius: 4px;
      height: 30px;
      outline: none;
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 35px;
      margin-left: 10px;
      border: none;
      border-radius: 4px;
      background-color: #252563;
      color: white;
      cursor: pointer;
      height: 30px;
  }
  
  }