.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4caf50;
    color: white;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .notification .para {
    margin: 0;
    flex: 1;
    padding-bottom: 0;
  }
  
  .close-btn {
    background: white;
    color: black;
    border-style: none;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 19px;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    text-align: center;
  }
  



  @media (max-width: 339px) {
    .notification{
        position: fixed;
        top: 20px;
        right: 14px;
        background-color: #4caf50;
        color: white;
        width: 280px;
        height: 78px;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    
  }



 