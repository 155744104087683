

.biology-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    margin-bottom: 20px;
    font-size: 2em;
    text-align: center;
  }
  
  .books-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .book-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 22%;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .book-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .book-details {
    padding: 10px;
  }
  
  .book-details h2 {
    font-size: 17px;
    margin-bottom: 10px;
    color: #000000bf;
    font-size: 'Playfair Display';
    font-weight: 400;
    display: none;

  }
  
  .book-details p {
    color: #000000bf;
    margin-bottom: 12px;
    font-size: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .book-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 13px;  }

    .lastpage{
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  
  .book-link {
    display: block;
    margin-top: 5px;
    color: #007bff;
    text-decoration: none;
  }
  
  .book-link:hover {
    text-decoration: underline;
  }

  .spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: auto; /* Center the spinner */
  }
  
  .lastpage p{
    text-align: center;
    margin-top: 5rem;
    font-size: 13px;
    color: #666;
    margin-bottom: -2rem;
  }


  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  @media (max-width: 1200px) {
    .book-card {
      width: 30%;
    }
  }
  
  @media (max-width: 768px) {
    .book-card {
      width: 45%;
    }
  }
  
  @media (max-width: 576px) {
    .book-card {
      width: 90%;
    }
  }
  