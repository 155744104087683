.google-form-toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.toggle-icon {
  cursor: pointer;
  color: #4285f4; /* Google blue */
}

.dropdown-container {
   position: absolute;
  top: 40px;
  width: 400px;
  background: #fff;
  left: 60px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}


.dropdown-container h3{
        color: #333;
    margin-bottom: 19px;
    font-family: 'Playfair Display';
    text-align: center;
    margin-top: 19px;

}

.dropdown-container h4{
     color: #333;
    font-family: 'Playfair Display';
    text-align: left;
        margin-top: 19px;
        margin-bottom: 19px;

}

.dropdown-container p{
    text-align: left;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cancel-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    cursor: pointer;
    color: #f44336;
    font-size: 20px;
}

.apply-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #02248a; /* Google blue */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.apply-button:hover {
  background-color: #357ae8; /* Darker blue */
}

.timeout-message {
  color: #ff5722; /* Orange */
  margin-top: 10px;
}

.googleformicon{
    border-radius: 50px;
    cursor: pointer;
}

.google-form-toggle img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    object-fit: cover;
    background: #e2e2e2;
    padding: 3px;
    border-radius: 22px;
    animation: zoomInOut 2.5s infinite;
}



@media (max-width: 700px) {

    .google-form-toggle img{
 width: 40px;
    height: 40px;
    cursor: pointer;
    object-fit: cover;
    background: #e2e2e2;
    padding: 3px;
    border-radius: 22px;
    animation: zoomInOut 2.5s infinite;
    }
}


@media (max-width: 480px) {

    .dropdown-container {
        position: sticky;
    top: 40px;
    width: 100%;
    background: #fff;
    left: 60px;
    transform: translateX(-3.5px);
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

}


   

@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
