/* src/Books.css */

.Famous-book{
    max-width: 85rem;
    width: 100%;
    margin: auto;
    padding-top: 5rem;
}


.Famous-book h2{
  color: #000000bf;
  margin: 50px;
  font-size: 30px;
  text-align: center;
  font-family: 'BebasNeue';

   }


.books-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    padding: 20px;
  }
  
  .book-card {
    flex: 1 1 calc(25% - 16px); /* 4 cards per row */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    background: #f8f8f8;
    border-radius: 8px;
    transition: ease-in-out 2sm;
  }

  .book-card:hover{
    background-color: white;
    opacity: 0.9;
    color: #fff;
  }
  
  .book-card img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .book-card h3 {
    margin-top: 10px;
    font-size: 16px;
    color: #000000bf;

  }
  
  /* Responsive design */
  @media (max-width: 1200px) {
    .book-card {
      flex: 1 1 calc(33.333% - 16px); /* 3 cards per row */
    }

     
  }
  
  @media (max-width: 768px) {
    .book-card {
      flex: 1 1 calc(50% - 16px); /* 2 cards per row */
    }

     

    .Famous-book h2 {
      color: #000000bf;
      margin: 13px;
      font-size: 25px;
      font-family: 'BebasNeue';}
  }
  
  
  