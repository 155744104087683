.contact{
   
  }
  
  .contactbg {
    background: url('../Images/home.0c7180386ca9923ba35c.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;  
  }
  
  .contacttext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 300px;
    max-width: 800px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contacttext h2 {
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    letter-spacing: 2px;
    line-height: 50px;
    text-align: center;
    text-transform: capitalize;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    color: #fff;
    font-family: 'BebasNeue';
  }

  .contactform{
    padding-top: 5rem;
  }

  .contactform h2{
    color: #000000bf;
    margin: auto;
    font-size: 35px;
    font-family: 'Playfair Display';
    margin-bottom: 38px;
    text-align: center;
  }
  



@media  (max-width: 600px){
  .contacttext h2{
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    letter-spacing: 2px;
    line-height: 50px;
    text-align: center;
    text-transform: capitalize;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    color: #fff;
    font-family: 'BebasNeue';
  }
}

  
@media (max-width: 930px) {
  
  .contacttext h2{
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.7em;
    letter-spacing: 2px;
    line-height: 50px;
    text-align: center;
    text-transform: capitalize;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    color: #fff;
    font-family: "Playfair Display", serif;
  }
}



@media (max-width: 768px) {
  .contacttext h2{
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    letter-spacing: 2px;
    line-height: 50px;
    text-align: center;
    text-transform: capitalize;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    color: #fff;
    font-family: "Playfair Display", serif;
  }

  .contactform h2{
    color: #000000bf;
    margin: auto;
    font-size: 25px;
    font-family: 'Playfair Display';
    margin-bottom: 38px;
  }
}


@media (max-width: 600px) {
  .contacttext h2{
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 2px;
    line-height: 50px;
    text-align: center;
    text-transform: capitalize;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    color: #fff;
    font-family: "Playfair Display", serif;
  }
}


