.login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f9;
    padding: 20px;
  }
  
  .login-container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Playfair Display';

  }
  
  p {
    color: #666;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 4px;
    margin-bottom: 15px;
    padding: 10px;
  }
  
  .input-container input {
    border: none;
    background: none;
    padding: 10px;
    flex: 1;
    outline: none;
  }
  
  .input-error {
    border: 2px solid red;
  }
  
  .toggle-password {
    cursor: pointer;
    color: #666;
  }
  
  .validation-error {
    color: red;
    font-size: 12px;
   
  }
  
  .reset-password-link {
    margin-bottom: 20px;
  }
  
  .reset-password-link a {
    color: blue;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .reset-password-link a:hover {
    color:  blue;
  }

  .btn{
    text-align: center;
  }
  
  .signin-button {
    background-color: #333;
    width: 100%;
    padding: 12px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .signin-button:hover {
    background-color: #333;
  }
  
  .signin-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .register-link {
    margin-top: 20px;
  }
  
  .register-link a {
    color: blue;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .register-link a:hover {
    color: blue;
  }
  
  @media (max-width: 768px) {
    .login-container {
      padding: 20px;
    }
  
    
    .login-container  h2 {
        color: #333;
        margin-bottom: 16px;
        font-family: 'Playfair Display';
        font-size: 16px;
      }
  
    .input-container input {
      padding: 10px;
    }
  }