.registration {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
  padding: 20px;
}

/* Add this to Registration.css */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 999;
}

.popup p{
  margin-bottom: 0px;
    color: white;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}

.registration-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

h2 {
  color: #333;
  margin-bottom: 20px;
  font-family: 'Playfair Display';
}

p {
  color: #666;
  margin-bottom: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.input-group {
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 5px;
}

.input-container input {
  border: none;
  background: none;
  padding: 10px;
  flex: 1;
  outline: none;
}

.input-error {
  border: 2px solid red;
}

.toggle-password {
  cursor: pointer;
  color: #666;
}

.red-error {
  color: red;
  font-size: 12px;
}

.signup-button {
  background-color: #333;
  width: 100%;
  padding: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.signup-button:hover {
  background-color: #333;
}

.signup-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-link {
  margin-top: 20px;
}

.login-link a {
  color: blue;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-link a:hover {
  color: blue;
}

@media (max-width: 768px) {
  .registration-container {
    padding: 20px;
  }

  .registration-container h2 {
    color: #333;
    margin-bottom: 16px;
    font-family: 'Playfair Display';
    font-size: 16px;
  }

  .input-container input {
    padding: 10px;
  }
}
