

.bookpage-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  h1 {
    color: #000000bf;
    margin: auto;
    font-size: 35px;
    font-family: 'Playfair Display';
    margin-bottom: 5rem;
  }
  
  .booksearch-container {
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .dropdown-containers {
    margin-bottom: 20px;
  }
  
  .category-select {
    font-size: 16px;
    border: none;
    outline: none;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .category-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .book-card {
    border: none;
    border-radius: 4px;
    padding: 10px;
    width: 22%;
    text-align: center;
  }
  
  .book-card h3 {
    font-size: 17px;
    margin-bottom: 10px;
    color: #000000bf;
    font-size: 'Playfair Display';
    font-weight: 400;
  }
  
  .book-card p {
    font-size: 16px;
    color: #555;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .book-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
  }
  
  .book-link {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  @media (max-width: 1200px) {
    .book-card {
      width: 30%;
    }

    
  h1 {
    color: #000000bf;
    margin: auto;
    font-size: 35px;
    font-family: 'Playfair Display';
    margin-bottom: 38px;
  }
  }
  
  @media (max-width: 768px) {
    .book-card {
      width: 45%;
    }

    
  h1 {
    color: #000000bf;
    margin: auto;
    font-size: 25px;
    font-family: 'Playfair Display';
    margin-bottom: 38px;
  }
  }
  
  @media (max-width: 576px) {
    .book-card {
      width: 90%;
    }

    h1 {
      color: #000000bf;
      margin: auto;
      font-size: 22px;
      font-family: 'Playfair Display';
      margin-bottom: 38px;
    }
  }
  