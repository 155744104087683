.contactform {
  width: 100%;
  max-width: 85rem;
  margin: auto;
  padding-bottom: 6rem;
}

.contactcon {
  max-width: 600px;
  width: 100%;
  background-color: #02248a;
  z-index: 100;
  margin: auto;
  border-radius: 10px;
}

#fancy {
  padding: 15px;
  width: auto;
  height: auto;
  border-radius: 10px;
}


#fancy h2 {
  font-size: 35px;
  padding-top: 32px;
  margin: 30px;
  color: white;
  font-family: 'Playfair Display';
  font-weight: 100;
}

#fancy form {
  display: flex;
  justify-content: center;
  align-items: center;
}

#fancy form .left {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.name-input, .email-input, .subject {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.name-input input, .email-input input, .subject select {
  font-size: 12px;
  color: #7e8798;
  border: none;
  max-width: 539px;
  height: 35px;
  background: #f1f1f1;
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 5px;
  width: 100%;
  outline-style: solid;
  outline-width: thin;
}

.name-input span, .email-input span, .subject span, .right textarea .icon {
  position: absolute;
  left: 8px;
  bottom: 25px;
  font-size: 13px;
  color: #7e8798;
}

.right {
  position: relative;
}

.textarea-container {
  position: relative;
}

.textarea-container textarea {
  height: 172px;
  font-size: 14px;
  color: #7e8798;
  border: none;
  background: none;
  width: 100%;
  padding: 18px 10px 18px 60px; /* Adjust padding to fit the icon */
  resize: none;
  outline-style: solid;
  outline-width: thin;
  background: #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
}

.textarea-container .icon {
  position: absolute;
  top: 18px; /* Adjust based on your padding */
  left: 20px; /* Adjust based on your padding */
  color: #7e8798; /* Match the color with the text */
  pointer-events: none; /* Ensure the icon does not interfere with typing */
}

.error {
  color: red;
}

.btn-holder {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 30px;
}

.btn-holder .btns {
  border: none;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 12px;
  padding-bottom: 12px;
}


@media (max-width: 600px) {

  #fancy form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#fancy {
  padding: 15px;
  width: auto;
  height: auto;
  background: #f5f5f59e;
  border-radius: 10px;
}

.contactcon {
  max-width: 600px;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  margin: auto;
  border-radius: 10px;
}

#fancy form .left{
  display: flex;
  flex-direction: column;
  margin-right: initial;
}

.name-input input, .email-input input, .subject select {
  font-size: 12px;
  color: #7e8798;
  border: none;
  height: 35px;
  background: #f1f1f1;
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 5px;
  width: 250px;
  outline-style: solid;
  outline-width: thin;
}

.textarea-container textarea {
  height: 172px;
  font-size: 14px;
  color: #7e8798;
  border: none;
  background: none;
  width: 250px;
  padding: 18px 10px 18px 60px;
  resize: none;
  outline-style: solid;
  outline-width: thin;
  background: #f1f1f1;
  box-sizing: border-box;
  border-radius: 5px;
}

#fancy h2 {
  font-size: 22px;
  padding-top: 32px;
  margin: 30px;
  color: #000000bf;
  font-family: 'Playfair Display';
  font-weight: 100;
}

.contactform h2{
  color: #000000bf;
  margin: auto;
  font-size: 25px;
  font-family: 'Playfair Display';
  margin-bottom: 38px;
}
}

@media (max-width: 300px) {
    .name-input input, .email-input input, .subject select {
      font-size: 12px;
      color: #7e8798;
      border: none;
      height: 35px;
      background: #f1f1f1;
      padding-left: 45px;
      padding-right: 45px;
      border-radius: 5px;
      width: 290px;
      outline-style: solid;
      outline-width: thin;
    }


    .textarea-container textarea {
      height: 172px;
      font-size: 14px;
      color: #7e8798;
      border: none;
      background: none;
      width: 290px;
      padding: 18px 10px 18px 60px;
      resize: none;
      outline-style: solid;
      outline-width: thin;
      background: #f1f1f1;
      box-sizing: border-box;
      border-radius: 5px;
  }

   #fancy h2 {font-size: 35px;
  padding-top: 32px;
  margin: 30px;
  color: #000000bf;
  font-family: 'Playfair Display';
  font-weight: 100;}
}