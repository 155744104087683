.About {
    font-family: Arial, sans-serif;

}

.about-container {
    background: url('../Images/home.0c7180386ca9923ba35c.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;  
}

.about-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-section h2 {
    color: white;
}

.about-details {
    display: grid;
    place-items: center;
    padding: 20px;
    max-width: 85rem;
    width: 100%;
    margin: auto;
    padding-bottom: 5rem;

}

.aboutgallerycontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;}

.para-about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-details p {
    text-decoration: none;
    color: #333;
    text-align: justify;
    font-size: 16px;
    line-height:20px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: 100%;
    max-width: 800px;
}

.paddingtoppara{
     text-decoration: none;
    color: #333;
    text-align: justify;
    font-size: 16px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-top: 2rem;
    line-height: 20px;
        max-width: 800px;

}

.image-gallery {
     width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #02248a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-gallery img {
       width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: center;
    max-width: 700px;
}


.about-details h3 {
        padding-top: 3rem;
    padding-bottom: 3rem;
    color: #000000bf;
    font-size: 30px;
    text-align: center;
    font-family: 'BebasNeue';
    max-width: 700px;
    width: 100%;
}

@media (max-width: 768px) {
    .about-details h3 {
        padding-top: 3rem;
    padding-bottom: 3rem;
    color: #000000bf;
    font-size: 22px;
    text-align: center;
    width: 100%;
    font-family: 'BebasNeue';
}
}