.admin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.admin-container {
       background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    height: 40vh;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;}

.admin-container h2 {
  margin-bottom: 14px;
}

.generate-button {
  background-color: #000000d1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.generate-button:hover {
  background-color: #0056b3;
}

.new-code {
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 20px;
}
